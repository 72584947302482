import React, {useCallback, useEffect, useState} from 'react';
import {getLocale, Locales, takeIf, useApi} from "@reactivers/hooks";
import {appStyles, EmptyResult, ListItem, Loading, Mapper, Modal, PageTitle, Show, Tag} from '@reactivers/neumorphic-ui'
import TagEdit from "./Edit";
import {EventListItem} from "../Event";

const TagList = () => {
    const {load, fetched, fetching, response} = useApi();

    const reload = useCallback(() => {
        load({
            endpoint: `/usertags`
        })
    }, [load])

    useEffect(() => {
        reload()
    }, [reload])

    const data = response.data || [];
    const hasData = !!data.length;

    return (
        <div style={{margin: "0 16px"}}>
            <PageTitle title={getLocale({name: Locales.Tags})}/>
            <Show condition={fetching}>
                <div style={{...appStyles.center}}><Loading/></div>
            </Show>
            <Show condition={fetched}>
                <Show condition={!hasData}>
                    <EmptyResult title={getLocale({name: Locales.EmptyTags})}/>
                </Show>
                <Show condition={hasData}>
                    <Mapper items={data}>
                        <TagListItem reload={reload}/>
                    </Mapper>
                </Show>
            </Show>
        </div>
    )
}

const TagListItem = props => {
    const {id, name, color, reload} = props;
    const [showTagModal, setShowTagModal] = useState(false);

    const onTagModalClose = useCallback(() => {
        setShowTagModal(false)
    }, [])

    const onSuccess = useCallback(() => {
        onTagModalClose();
        reload()
    }, [onTagModalClose, reload])


    const showEditTagModal = useCallback((tag) => {
        setShowTagModal(true)
    }, [])

    return (
        <div>
            <TagFormModal visible={showTagModal}
                          id={id}
                          onSaveSuccess={onSuccess}
                          onDeleteSuccess={onSuccess}
                          onCancel={onTagModalClose}
            />
            <ListItem titleRenderer={
                <Tag
                    style={{width: '100%', display: 'flex', fontSize: 18, maxWidth: '100%'}}
                    description={name}
                    color={color}
                    type="filled"
                    onClick={showEditTagModal}>
                    {name}
                </Tag>
            } titleContainerStyle={{display: 'flex'}}/>
            <EventListLoader userTagId={id}/>
        </div>
    )
}

const EventListLoader = props => {
    const {userTagId} = props;
    const {load, fetching, response} = useApi();
    const reload = useCallback(() => {
        load({
            endpoint: `/usertags/${userTagId}/events`
        })
    }, [load, userTagId])

    useEffect(() => {
        reload()
    }, [reload])

    const data = response.data || [];
    const hasData = !!data.length;
    if (fetching) return <div style={{minHeight: 100, ...appStyles.center}}><Loading/></div>
    if (hasData)
        return (
            <Mapper items={data}>
                <EventListItem reload={reload}/>
            </Mapper>
        )
    return <EmptyResult style={{marginTop: 16}} title={getLocale({name: Locales.EmptyEvents})}/>

}

const TagFormModal = props => {
    const {id, visible, onCancel} = props;
    const title = takeIf(id, getLocale({name: Locales.EditTag}), getLocale({name: Locales.NewTag}))
    return (
        <Modal title={title}
               visible={visible}
               onClose={onCancel}
               destroyOnClose
               footer={null}
        >
            <TagEdit {...props} />
        </Modal>
    )
}

export default TagList;
