import React, {useCallback, useEffect, useState} from 'react';
import {getFirstLetters, getLocale, Locales, useApi, useAuth, useHistory} from "@reactivers/hooks";
import {Button, Card, Field, Form, PageTitle, Textfield, useForm} from '@reactivers/neumorphic-ui'
import ImageUploadFormItem from "../../components/ImageUploadFormItem";

const ProfileEdit = props => {
    const user = useAuth();
    const {fullName} = user;
    const [init, setInit] = useState(false)
    const {load} = useApi();
    const history = useHistory();
    const [form] = useForm();

    const goBack = useCallback(() => {
        history.goBack()
    }, [history])

    const onEdit = useCallback((values) => {
        load({
            endpoint: '/users',
            method: "POST",
            params: {
                ...user,
                ...values
            },
            onSuccess: (response) => {
                user.update(response.data);
                goBack()
            }
        })
    }, [load, user, goBack])

    useEffect(() => {
        if (!init) {
            setInit(true)
            form.setFieldsValue({...user})
        }
    }, [init, form, user])

    return (
        <div style={{margin: '0 16px'}}>
            <PageTitle title={getLocale({name: Locales.UpdateProfile})}/>
            <Card style={{backgroundColor: 'white', width: '100%', padding: 16}}>
                <Form onFinish={onEdit}
                      form={form}>
                    <ImageUploadFormItem name={"image"}
                                         placeholder={getFirstLetters(fullName)}/>
                    <Field name="email">
                        <Textfield label="E-mail"/>
                    </Field>
                    <Field name="firstName">
                        <Textfield label={getLocale({name: Locales.FirstName})}/>
                    </Field>
                    <Field name="lastName">
                        <Textfield label={getLocale({name: Locales.LastName})}/>
                    </Field>
                    <Field name="phoneNumber">
                        <Textfield label={getLocale({name: Locales.PhoneNumber})}/>
                    </Field>
                    <div style={{margin: '16px 0'}}>
                        <div>
                            <Button onClick={goBack}
                                    style={{marginRight: 16}}>
                                {getLocale({name: Locales.Decline})}
                            </Button>
                            <Button type="primary"
                                    htmlType="submit">
                                {getLocale({name: Locales.Save})}
                            </Button>
                        </div>
                    </div>
                </Form>
            </Card>
        </div>
    )
}

export default ProfileEdit;
