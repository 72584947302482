import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import '@reactivers/neumorphic-ui/dist/bundle.css'
import {getLocale, setAppURLs, setMainColor, setRoutes, StoreProvider} from "@reactivers/hooks";
import {authorizedRoutes, publicRoutes} from "./routes";
import moment from 'moment';
import {initLocales} from "./utils/functions";

moment.lang(navigator.language)

initLocales()
const updateRouteTitles = () => {
    authorizedRoutes.forEach(i => {
        i.title = getLocale({name: i.title})
    })
};
updateRouteTitles();
window.moment = moment;

setMainColor("#1890ff")

setAppURLs({
    HTTP_REST_SERVER: {
        development: `/api`,
        production: "/api"
    },
    WS_REST_SERVER: {
        development: `ws:/192.168.0.36:5000/ws`,
        production: "ws://api.takvim.tk/ws"
    }
})

setRoutes({
    publicRoutes,
    authorizedRoutes
})

window.env = process.env

ReactDOM.render(
    <StoreProvider>
        <App/>
    </StoreProvider>
    ,
    document.getElementById('root')
);


