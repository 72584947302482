import Home from "../pages/Home";
import {Redirect} from "@reactivers/neumorphic-ui";
import {getLocale, Locales} from "@reactivers/hooks";
import SignIn from "../pages/Public/Sign/SignIn";
import SignUp from "../pages/Public/Sign/SignUp";
import AgendaView from "../pages/Agenda";
import {CalendarOutlined, HomeOutlined, SearchOutlined, TagOutlined} from "@ant-design/icons";
import InviteList from "../pages/Invites";
import EventList from "../pages/Event";
import TagList from "../pages/Tags";
import Profile from "../pages/Profile";
import ProfileEdit from "../pages/Profile/Edit";
import SearchPage from "../pages/Search";

export const publicRoutes = [
    {
        path: '/',
        title: getLocale({name: Locales.Home}),
        component: () => <Redirect redirectURL="/sign/in"/>
    },
    {
        path: '/sign/in',
        title: getLocale({name: Locales.SignIn}),
        component: SignIn
    },
    {
        path: '/sign/up',
        title: getLocale({name: Locales.SignUp}),
        component: SignUp
    },
    {
        path: '*',
        component: () => <Redirect redirectURL="/"/>
    }
]

export const authorizedRoutes = [
    {
        path: '/',
        icon: HomeOutlined,
        title: getLocale({name: Locales.Home}),
        showInMenu: true,
        component: Home
    },
    {
        path: '/search',
        title: getLocale({name: Locales.Search}),
        icon: SearchOutlined,
        showInMenu: true,
        component: SearchPage
    },
    {
        path: '/agenda',
        title: "Agenda",
        icon: CalendarOutlined,
        showInMenu: true,
        component: AgendaView
    },
    {
        path: '/invites',
        title: "Invitations",
        icon: CalendarOutlined,
        component: InviteList
    },
    {
        path: '/events',
        title: "Events",
        icon: CalendarOutlined,
        showInMenu: true,
        component: EventList
    },
    {
        path: '/tags',
        title: "Tags",
        icon: TagOutlined,
        showInMenu: true,
        component: TagList
    },
    {
        path: '/profile',
        title: "Profil",
        component: Profile
    },
    {
        path: '/profile/edit',
        title: "Profil Güncelle",
        component: ProfileEdit
    },
    {
        path: '/sign/in',
        component: () => <Redirect redirectURL="/"/>
    },
    {
        path: '/sign/up',
        component: () => <Redirect redirectURL="/"/>
    }
];
