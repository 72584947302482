import React, {useCallback, useState} from 'react';
import {authorizedRoutes} from "../routes";
import {
    getFirstLetters,
    getLocale,
    getMainColor,
    Locales,
    takeIf,
    useAuth,
    useDimensions,
    useHistory,
} from "@reactivers/hooks";
import {appStyles, Button, Image, ListItem, Show, Tag} from '@reactivers/neumorphic-ui'
import {InboxOutlined, LogoutOutlined, PlusOutlined} from "@ant-design/icons";
import {EventFormModal} from "../pages/Event/EventListRenderer";
import moment from "moment";
import {getImageUrl} from "../utils/functions";

const AuthorizedLayout = props => {
    const {children} = props;
    return (
        <div style={{minHeight: '100vh', backgroundColor: '#eee'}}>
            <Header style={{padding: 0, backgroundColor: 'transparent'}}/>
            <div style={{...appStyles.row, width: '100%'}}>
                <NavigationMenu/>
                <Content>
                    {children}
                </Content>
            </div>
        </div>
    );
}

const Content = props => {
    const {children} = props;
    const {isSizeSmallerThan} = useDimensions()
    const isMobil = isSizeSmallerThan("md")
    return (
        <div style={{width: '100%', paddingBottom: takeIf(isMobil, 80)}}>
            {children}
        </div>
    )
}

const Header = props => {
    return (
        <div style={{height: 64, width: '100%', ...appStyles.spreadHorizontally}}>
            <HeaderLeftContent/>
            <HeaderMiddleContent/>
            <HeaderRightContent/>
        </div>
    )
}

const HeaderLeftContent = props => {
    return (
        <div style={{width: '100%'}}/>
    )
}

const HeaderMiddleContent = props => {
    return (
        <div style={{width: '100%'}}/>
    )
}

const HeaderRightContent = props => {
    const user = useAuth();
    const history = useHistory();
    const {pathname} = history.location;
    const selected = pathname.indexOf("/profile") > -1
    const {isSizeSmallerThan} = useDimensions();
    const hideDescription = isSizeSmallerThan("sm");
    return (
        <div style={{width: '100%', ...appStyles.row, justifyContent: 'flex-end', paddingRight: 20}}>
            <NewEventButton/>
            <InvitationsButton/>
            <UserTag style={{padding: 4, marginLeft: 8}}
                     user={user}
                     selected={selected}
                     hideDescription={hideDescription}
                     isActiveUser/>
        </div>
    )
}

const NewEventButton = props => {
    const [showEventFormModal, setShowEventFormModal] = useState(false)
    const {isSizeSmallerThan} = useDimensions();

    const hideTitle = isSizeSmallerThan("sm");

    const onShowEventFormModal = useCallback(() => {
        setShowEventFormModal(true)
    }, [])

    const onCloseEventFormModal = useCallback(() => {
        setShowEventFormModal(false)
    }, [])

    const title = takeIf(hideTitle, undefined, getLocale({name: Locales.NewEvent}));
    const date = moment().format("YYYY-MM-DD")
    return (
        <>
            <EventFormModal date={date} visible={showEventFormModal}
                            onClose={onCloseEventFormModal}
                            onSuccess={onCloseEventFormModal}
            />
            <Button icon={<PlusOutlined style={{fontSize: 20}}/>}
                    iconSize={40}
                    type="primary"
                    style={{marginRight: 8}}
                    onClick={onShowEventFormModal}
                    title={title}/>
        </>
    )
}

const InvitationsButton = props => {
    const history = useHistory();
    const {isSizeSmallerThan} = useDimensions();

    const {pathname} = history.location
    const selected = pathname === "/invites"
    const hideTitle = isSizeSmallerThan("sm");

    const pushToInvitations = useCallback(() => {
        history.push(`/invites`)
    }, [history])

    const title = takeIf(hideTitle, undefined, getLocale({name: Locales.Invitations}));

    return (
        <Button icon={<InboxOutlined style={{fontSize: 20}}/>}
                iconSize={40}
                selected={selected || undefined}
                style={{color: takeIf(selected, "#1890ff")}}
                onClick={pushToInvitations}
                title={title}/>
    )
}

export const UserTag = props => {
    const {user, neumorphic, selected, hideDescription, style, isActiveUser} = props;
    const history = useHistory();
    const {fullName, email, image} = user || {}
    const {id: imageId} = image || {}
    const {logout} = user || {};
    const decsription = (fullName || "").trim() || email
    const pushToProfile = useCallback(() => {
        history.push('/profile')
    }, [history])

    const onLogout = useCallback(() => {
        logout();
    }, [logout])

    return (
        <Tag description={decsription}
             onClick={pushToProfile}
             style={style}
             neumorphic={neumorphic}
             className={`${takeIf(selected, "neumorphic-outset-inset")}`}
             generatedColor>
            <div style={{...appStyles.row, alignItems: 'center'}}>
                <div style={{marginRight: 8, borderRadius: '50%'}}>
                    <Image src={getImageUrl(imageId)}
                           size={24}
                           placeholder={getFirstLetters(decsription)}
                    />
                </div>
                <Show condition={!hideDescription}>
                    <div style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>
                        {decsription}
                    </div>
                </Show>
                <Show condition={isActiveUser}>
                    <div>
                        <Button icon={<LogoutOutlined style={{color: 'white'}}/>}
                                onClick={onLogout}
                                style={{marginLeft: 8}}/>
                    </div>
                </Show>
            </div>
        </Tag>
    )
}

const NavigationMenu = props => {
    const history = useHistory();
    const {isSizeSmallerThan} = useDimensions();
    const smallerThanSm = isSizeSmallerThan("sm");
    const smallerThanMd = isSizeSmallerThan("md");
    const mode = takeIf(smallerThanMd, "bottom", "left");

    const pushToPath = useCallback((path) => {
        history.push(path)
    }, [history])

    const {pathname} = history.location

    const menuItems = authorizedRoutes.filter(i => i.showInMenu).map((route, index) => {
        const {icon, title, path} = route;
        const selected = pathname === path;

        const iconComp = React.createElement(icon, {
            style: {
                color: takeIf(selected, '#1890ff')
            }
        });

        if (smallerThanSm)
            return (
                <Button key={index}
                        icon={iconComp}
                        iconSize={40}
                        style={{
                            marginLeft: takeIf(index, 24, 0),
                            color: takeIf(selected, getMainColor())
                        }}
                        selected={!!selected}
                        onClick={() => pushToPath(path)}/>
            )
        return (
            <MenuItem key={index}
                      icon={iconComp}
                      title={title}
                      selected={selected}
                      style={{marginBottom: takeIf(mode === "bottom", 0, 8)}}
                      onClick={() => pushToPath(path)}/>
        )
    })

    const leftNavigation = <Menu alignment="vertical"
                                 style={{
                                     position: 'relative',
                                     flex: '0 0 200px',
                                     maxWidth: 200,
                                     minWidth: 200,
                                     width: 200,
                                     backgroundColor: 'transparent',
                                     marginLeft: 4
                                 }}>
        {menuItems.map(i => i)}
    </Menu>

    const bottomNavigation = <div
        style={{
            position: 'fixed',
            backgroundColor: '#f0f2f5',
            left: 0,
            bottom: 0,
            width: '100%',
            zIndex: 1,
            height: 64,
        }}>
        <Menu
            alignment="vertical"
            style={{
                ...appStyles.row,
                ...appStyles.center,
                gap: 16,
                height: '100%',
                boxShadow: '0 -5px 5px 0 rgba(255,255,255,1)',
                position: 'relative',
                backgroundColor: 'transparent',
            }}>
            {menuItems.map(i => i)}
        </Menu>
    </div>

    if (mode === "left")
        return leftNavigation
    return bottomNavigation
}

const Menu = props => {
    const {children, alignment, ...rest} = props;
    return <div {...rest}>{children}</div>;
}

const MenuItem = props => {
    const {selected, icon, onClick, title, style} = props;
    return <ListItem selected={selected}
                     avatar={icon}
                     onClick={onClick}
                     title={title}
                     titleStyle={{
                         color: takeIf(selected, getMainColor())
                     }}
                     style={{
                         padding: "4px 16px",
                         marginBottom: 8,
                         borderRadius: 10,
                         ...(style || {}),
                     }}
    />
}

export default AuthorizedLayout;
