import React from 'react';
import AuthorizedLayout from "./layout";
import {AppWrapper} from "@reactivers/hooks";

const App = () => {
    return (
        <AppWrapper
            userLoadOptions={{
                endpoint: '/users/getUserInfo',
            }}
            authorizedLayout={AuthorizedLayout}
            publicLayout={PublicLayout}
        />
    );
}

const PublicLayout = props => {
    const {children} = props;
    return (
        <div style={{minHeight: '100vh'}}>
            {children}
        </div>
    );
}

export default App;
