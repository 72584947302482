import React from "react";
import {Col} from '@reactivers/neumorphic-ui';

const Column = props => {
    return (
        <Col xs={12} sm={12} md={6} lg={3} xl={3} {...props}/>
    )
}

export default Column;
