import {generatedColorFromString, getLocale, Locales, numberShouldStartWithZero, useApi} from "@reactivers/hooks";
import React, {useCallback, useEffect, useState} from "react";
import {
    appStyles,
    Button,
    ColorPicker,
    Field,
    Form,
    ListItem,
    Loading,
    Modal,
    Show,
    Tag,
    Textfield,
    TextListField,
    useForm
} from '@reactivers/neumorphic-ui'
import moment from "moment";
import {CheckOutlined, CloseOutlined, MoreOutlined, UserAddOutlined} from "@ant-design/icons";
import InviteModal from "../Invites/Edit";

const EventForm = (props) => {
    const {date, onClose, onSuccess, onDeleteSuccess, id} = props;
    const [form] = useForm();
    const {load, fetching, response} = useApi();
    const event = response.data;
    const {load: loadSave, fetching: fetchingSave} = useApi();
    const {load: loadDelete, fetching: deleteFetching} = useApi();
    const {collaborators: _collaborators} = event || {};
    const collaborators = _collaborators || []
    const [visibleInviteModal, setVisibleInviteModal] = useState(false);
    const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);

    const deleteEvent = useCallback(() => {
        loadDelete({
            endpoint: `/events/${id}`,
            method: "DELETE",
            onSuccess: onDeleteSuccess
        })
    }, [loadDelete, onDeleteSuccess, id])

    const showDeleteModal = useCallback(() => {
        setVisibleDeleteModal(true)
    }, [])

    useEffect(() => {
        load({
            endpoint: `/events/${id}`
        })
    }, [load, id])

    const initForm = useCallback(() => {

        const {startDateTime: _startDateTime, endDateTime: _endDateTime} = event || {}
        let startDateTime;
        let endDateTime;
        const selectedDate = new Date(date);
        const datePart = selectedDate.getDate();
        const monthPart = numberShouldStartWithZero(selectedDate.getMonth() + 1);

        const yearPart = selectedDate.getFullYear();
        const nowHour = moment().format("HH");
        const nowMinute = moment().format("mm");
        const nowSecond = moment().format("ss");
        const selectedDateTime = moment(`${yearPart}-${monthPart}-${datePart}`).add(nowHour, "hour").add(nowMinute, "minute").add(nowSecond, "second");

        if (_startDateTime) {
            startDateTime = moment(_startDateTime)
        } else {
            startDateTime = selectedDateTime
        }

        if (_endDateTime) {
            endDateTime = moment(_endDateTime)
        } else {
            endDateTime = selectedDateTime;
        }

        form.setFieldsValue({
            ...event,
            startDateTime: startDateTime.format("HH:mm"),
            endDateTime: endDateTime.format("HH:mm")
        })
    }, [date, event, form])

    useEffect(() => {
        initForm()
    }, [initForm])

    const onFinish = useCallback((values) => {
        let endpoint = id ? `/events/${id}` : `/events`;
        let {startDateTime, endDateTime} = values;
        const {startDateTime: oldStartDateTime, endDateTime: oldEndDateTime} = event || {};
        const [startDateTimeHour, startDateTimeMinute] = startDateTime.split(":")
        const [endDateTimeHour, endDateTimeMinute] = endDateTime.split(":")

        startDateTime = moment(oldStartDateTime || date).set({hour: startDateTimeHour, minute: startDateTimeMinute})
        endDateTime = moment(oldEndDateTime || date).set({hour: endDateTimeHour, minute: endDateTimeMinute})

        const params = {
            ...event,
            ...values,
            startDateTime: moment(startDateTime).format(),
            endDateTime: moment(endDateTime).format(),
        }
        loadSave({
            endpoint,
            method: "POST",
            params,
            onSuccess
        })

    }, [id, event, date, loadSave, onSuccess])

    const valuesRenderer = useCallback((params) => {
        const {item, index, onClear, setValue} = params;
        const {content} = item;
        return (
            <ListItem avatar={<i style={{minWidth: 8, minHeight: 8, borderRadius: 8, backgroundColor: 'black'}}/>}
                      title={content}
                      description={
                          <Button icon={<CloseOutlined/>}
                                  onClick={onClear}/>
                      }
                      key={index}
                      onTitleClick={() => setValue({...item, index})}>
            </ListItem>
        )
    }, [])

    const tagValuesRenderer = useCallback((params) => {
        const {item, index, onClear, setValue, onChange} = params;
        const {tagName, tagColor} = item;
        return (
            <Tag key={index}
                 color={tagColor}
                 type="filled"
                 generatedColor
                 style={{marginBottom: 4, marginRight: 4}}
                 description={tagName}
                 closeIcon={<CloseOutlined style={{fontSize: 11}}/>}
                 onClear={() => onClear(index)}>
                <div style={{...appStyles.spreadHorizontally, minWidth: 64}}>
                    <div style={{marginLeft: 4, lineHeight: 1.4}}
                         onClick={() => setValue({...item, index})}>
                        {tagName}
                    </div>
                    <ColorPicker value={tagColor} onChange={(color) => {
                        onChange(index, {...item, tagColor: color})
                    }}>
                        <Button style={{marginLeft: 16}} soft icon={<MoreOutlined style={{fontSize: 11}}/>}/>
                    </ColorPicker>

                </div>
            </Tag>
        )
    }, [])

    const tagValueTransformer = value => {

        return {
            ...value,
            tag: {
                color: value.tagColor || generatedColorFromString(value.tagName || ""),
                name: value.tagName,
                id: value.tagId
            }
        };
    }

    const showInviteModal = useCallback(() => {
        setVisibleInviteModal(true)
    }, [])

    const onCloseInviteModal = useCallback(() => {
        setVisibleInviteModal(false)
    }, [])

    const onCloseDeleteModal = useCallback(() => {
        setVisibleDeleteModal(false)
    }, [])

    return (
        <div>
            <InviteModal visible={visibleInviteModal} onClose={onCloseInviteModal} eventId={id}/>
            <Modal title="Etkinliği silmek istediğinize emin misiniz?"
                   onClose={onCloseDeleteModal}
                   visible={visibleDeleteModal}
                   footer={
                       <>
                           <Button onClick={onCloseDeleteModal}>Vazgeç</Button>
                           <Button type='danger' onClick={deleteEvent}>Sil</Button>
                       </>
                   }
            />
            <Show condition={id && fetching}>
                <Loading/>
            </Show>

            <Show condition={!id || !fetching}>
                <Form onFinish={onFinish} form={form}>
                    <Field name="title">
                        <Textfield label={getLocale({name: Locales.Title})}/>
                    </Field>
                    <div style={{...appStyles.row, gap: 24}}>
                        <Field name="startDateTime" label="" rules={[{required: true, message: 'Zorunlu Alan!'}]}>
                            <Textfield label={getLocale({name: Locales.StartTime})}/>
                        </Field>
                        <Field name="endDateTime" label="" rules={[{required: true, message: 'Zorunlu Alan!'}]}>
                            <Textfield label={getLocale({name: Locales.EndTime})}/>
                        </Field>
                    </div>
                    <Field name="descriptions">
                        <TextListField valuesRenderer={valuesRenderer}
                                       label={getLocale({name: Locales.Descriptions})}
                                       checkIcon={<CheckOutlined/>}
                                       listContainerStyle={{display: 'block', listStyle: 'disc'}}
                                       descriptionKey="content"/>
                    </Field>
                    <Field name="color" initialValue="black">
                        <ColorPicker label={getLocale({name: Locales.Color})}/>
                    </Field>
                    <Field name="tags">
                        <TextListField valuesRenderer={tagValuesRenderer}
                                       label={getLocale({name: Locales.Tags})}
                                       checkIcon={<CheckOutlined/>}
                                       valueTransformer={tagValueTransformer}
                                       descriptionKey="tagName"/>
                    </Field>
                    <Show condition={id}>
                        <div style={{margin: '16px 0'}}>
                            <p>{getLocale({name: Locales.Shared})}</p>
                            <div style={{...appStyles.spreadHorizontally}}>
                                <div style={{...appStyles.grid}}>
                                    {collaborators.map((collaborator, index) => {
                                        const {username} = collaborator;
                                        return (
                                            <Tag description={username + ""}
                                                 generatedColor
                                                 style={{display: 'flex', marginBottom: 8, marginRight: 8}}
                                                 textStyle={{padding: 2}}
                                                 key={index}>
                                                {username}
                                            </Tag>
                                        )
                                    })}
                                </div>
                                <Button type="link"
                                        icon={<UserAddOutlined/>}
                                        onClick={showInviteModal}>
                                    {getLocale({name: Locales.New})}
                                </Button>
                            </div>
                        </div>
                    </Show>
                    <div style={{margin: '16px 0'}}>
                        <Button type="secondary" disabled={fetchingSave || deleteFetching} onClick={onClose}>
                            {getLocale({name: Locales.Decline})}
                        </Button>
                        <Show condition={!!id}>
                            <Button type="danger" style={{marginLeft: 8}}
                                    disabled={fetching || fetchingSave}
                                    loading={deleteFetching}
                                    onClick={showDeleteModal}>
                                {getLocale({name: Locales.Delete})}
                            </Button>
                        </Show>
                        <Button type="primary"
                                loading={fetchingSave}
                                disabled={fetching || deleteFetching} style={{marginLeft: 8}}
                                htmlType="submit">
                            {getLocale({name: Locales.Save})}
                        </Button>
                    </div>
                </Form>
            </Show>
        </div>
    )
}

export default EventForm
