import React, {useCallback, useEffect} from "react";
import moment from "moment";
import {getLocale, Locales, useApi} from "@reactivers/hooks";
import {appStyles, Card, Col, EmptyResult, Loading, PageTitle} from '@reactivers/neumorphic-ui'
import EventListRenderer from "../Event/EventListRenderer";

const Home = (props) => {
    return (
        <div style={{margin: '0 16px'}}>
            <PageTitle title={getLocale({name: Locales.Home})}/>
            <div style={{...appStyles.grid}}>
                <Col xs={12} sm={12} md={6} lg={3} style={{padding: 8}}>
                    <TodayEvents/>
                </Col>
                <Col xs={12} sm={12} md={6} lg={3} style={{padding: 8}}>
                    <TomorrowEvents/>
                </Col>
                <Col xs={12} sm={12} md={6} lg={3} style={{padding: 8}}>
                    <ThisWeekEvents/>
                </Col>
                <Col xs={12} sm={12} md={6} lg={3} style={{padding: 8}}>
                    <ThisMonthEvents/>
                </Col>
            </div>
        </div>
    )
}

const TodayEvents = props => {
    const today = moment().format("YYYY-MM-DD");
    return (
        <Card title={getLocale({name: Locales.Today})} childrenContainerStyle={{margin: 0}}>
            <FilteredEvents startDate={today}/>
        </Card>
    )
}

const TomorrowEvents = props => {
    const today = moment().add(1, 'day').format("YYYY-MM-DD");
    return (
        <Card title={getLocale({name: Locales.Tomorrow})} childrenContainerStyle={{margin: 0}}>
            <FilteredEvents startDate={today}/>
        </Card>
    )
}

const ThisWeekEvents = props => {
    const startDate = moment().format("YYYY-MM-DD");
    const endDate = moment().add(7, 'day').format("YYYY-MM-DD");
    return (
        <Card title={getLocale({name: Locales.ThisWeek})} childrenContainerStyle={{margin: 0}}>
            <FilteredEvents startDate={startDate} endDate={endDate}/>
        </Card>
    )
}

const ThisMonthEvents = props => {
    const startDate = moment().format("YYYY-MM-DD");
    const endDate = moment().add(30, 'day').format("YYYY-MM-DD");
    return (
        <Card title={getLocale({name: Locales.ThisMonth})} childrenContainerStyle={{margin: 0}}>
            <FilteredEvents startDate={startDate} endDate={endDate}/>
        </Card>
    )
}

const FilteredEvents = props => {
    const {startDate, endDate} = props;
    const {load, response, fetching} = useApi();

    const reload = useCallback(() => {
        const options = {

            endpoint: `/events/filter/start_date/${startDate}`
        }
        if (!!endDate)
            options.endpoint += `/end_date/${endDate}`

        options.endpoint += `/paged/1/5`;
        load({
            ...options
        })
    }, [startDate, endDate, load])

    useEffect(() => {
        reload()
    }, [reload])

    const {data} = response
    const {results} = data || {};
    const events = results || []
    const hasData = !!events.length

    if (fetching) return <Loading/>
    if (!hasData) return <EmptyResult title={getLocale({name: Locales.EmptyEvents})}/>
    return <EventListRenderer mini events={events} colProps={{
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24,
    }}/>
}

export default Home;
