import React, {useCallback, useState} from 'react';
import EventForm from "./Edit";
import EventRenderer from "./Renderer";
import {
    deleteElementFromArrayByKey,
    getLocale,
    insertOrUpdateElementInArrayByKey,
    Locales,
    useApi
} from "@reactivers/hooks";
import {appStyles, Modal} from '@reactivers/neumorphic-ui'
import Column from "../../components/Column";

export const EventFormModal = props => {
    const {visible, onClose, ...rest} = props;
    return (
        <Modal visible={visible}
               footer={null}
               title={getLocale({name: Locales.NewEvent})}
               style={{backgroundColor: '#eee'}}
               destroyOnClose
               closable={false}
               onClose={onClose}>
            <EventForm onClose={onClose} {...rest}/>
        </Modal>
    )
}

const EventListRenderer = props => {
    const {events, colProps, mini, onEventClick: _onEventClick} = props;
    const [eventFormModal, setEventFormModal] = useState({visible: false})
    const {load} = useApi();

    const reload = useCallback(({data}) => {
        const {id} = data;
        load({
            endpoint: `/events/${id}`,
            onSuccess: (response) => {
                insertOrUpdateElementInArrayByKey(events, "id", response.data.id, response.data)
            }
        })
    }, [events, load])

    const cloneEvent = useCallback((event) => {
        return JSON.parse(JSON.stringify(event));
    }, [])

    const onEventClick = useCallback(({id}, index) => {
        if (_onEventClick) {
            _onEventClick();
        } else {
            setEventFormModal({visible: true, id})
        }
    }, [_onEventClick])

    const onEventFormModalClose = useCallback(() => {
        setEventFormModal({visible: false})
    }, [])

    const onEventFormModalSuccess = useCallback((response) => {
        onEventFormModalClose();
        reload(response);
    }, [reload, onEventFormModalClose])

    const onEventFormModalDeleteSuccess = useCallback((response) => {
        onEventFormModalClose();
        deleteElementFromArrayByKey(events, "id", response.id);
    }, [events, onEventFormModalClose])

    return <div style={{...appStyles.grid}}>
        <EventFormModal {...eventFormModal}
                        onClose={onEventFormModalClose}
                        onSuccess={onEventFormModalSuccess}
                        onDeleteSuccess={onEventFormModalDeleteSuccess}
        />
        {
            (events || []).map((event, index) => {
                return (
                    <Column {...(colProps || {})} key={index}>
                        <div style={{padding: 4}}>
                            <EventRenderer {...event}
                                           mini={mini}
                                           reload={reload}
                                           onClick={() => onEventClick(cloneEvent(event), index)}/>
                        </div>
                    </Column>
                )
            })
        }
    </div>
}

export default EventListRenderer;
