import React, {useCallback} from 'react';
import {getLocale, Locales, useApi, useAuth, useHistory} from "@reactivers/hooks";
import {appStyles, Button, Card, Field, Form, Textfield} from '@reactivers/neumorphic-ui'

const SignUp = props => {
    const {login} = useAuth();
    const {load} = useApi();
    const history = useHistory();

    const onSuccess = useCallback((response) => {
        const {data} = response;
        login(data);
    }, [login])

    const onSignUp = useCallback((values) => {
        load({
            endpoint: '/users/signup',
            method: "POST",
            params: {
                ...values
            },
            onSuccess
        })
    }, [load, onSuccess])

    const pushToSignIn = useCallback(() => {
        history.push("/sign/in")
    }, [history])


    return (
        <div style={{height: '100vh', ...appStyles.center, backgroundColor: '#eee'}}>
            <div style={{minWidth: 300}}>
                <Card title={getLocale({name: Locales.SignUp})}
                      childrenContainerStyle={{margin: 0}}
                      style={{backgroundColor: 'white', width: '100%', padding: 16}}>
                    <Form onFinish={onSignUp}>
                        <Field name="email" rules={[{type: "email"}]}>
                            <Textfield label="E-mail" autoComplete="username"/>
                        </Field>
                        <Field name="firstName">
                            <Textfield label={getLocale({name: Locales.FirstName})} autoComplete="given-name"/>
                        </Field>
                        <Field name="lastName">
                            <Textfield label={getLocale({name: Locales.LastName})} autoComplete="family-name"/>
                        </Field>
                        <Field name="password">
                            <Textfield label={getLocale({name: Locales.Password})} type="password" autoComplete="current-password"/>
                        </Field>
                        <div style={{margin: '16px 0'}}>
                            <div style={{...appStyles.spreadHorizontally}}>
                                <Button type="link" onClick={pushToSignIn} style={{width: '100%', marginRight: 4}}>
                                    {getLocale({name: Locales.AlreadyHaveAnAccount})}?
                                </Button>
                                <Button type="primary" style={{width: '100%', marginLeft: 4}} htmlType="submit">
                                    {getLocale({name: Locales.SignUp})}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </Card>
            </div>
        </div>
    )
}

export default SignUp;
