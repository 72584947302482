import React, {useCallback, useEffect, useState} from 'react';
import {getLocale, Locales, useApi} from "@reactivers/hooks";
import {Button, EmptyResult, Loading, Mapper, PageTitle, Show, Textfield} from '@reactivers/neumorphic-ui'
import {SearchOutlined} from "@ant-design/icons";
import {EventListItem} from "../Event";

const SearchPage = props => {
    const [tempSearch, setTempSearch] = useState("")
    const [search, setSearch] = useState("")
    const {load, fetched, fetching, response} = useApi();

    const reload = useCallback(() => {
        load({
            endpoint: `/events/search/quick`,
            method: "POST",
            params: {
                name: search
            }
        })
    }, [load, search])

    const onChange = useCallback((e) => {
        setTempSearch(e.target.value)
    }, [])

    const onSearch = useCallback(() => {
        setSearch(tempSearch)
    }, [tempSearch])

    useEffect(() => {
        reload()
    }, [reload])

    const {data: _data} = response;
    const data = _data || [];
    const hasData = !!data.length
    return (
        <div style={{margin: '0 16px'}}>
            <PageTitle title={getLocale({name: Locales.Search})}/>
            <div style={{marginBottom: 16}}>
                <Textfield value={tempSearch}
                           onChange={onChange}
                           placeholder={`${getLocale({name: Locales.Search})}...`}
                           onPressEnter={onSearch}
                           suffix={<Button type='primary' icon={<SearchOutlined/>} onClick={onSearch}/>}
                />
            </div>
            <Show condition={fetching}>
                <Loading/>
            </Show>
            <Show condition={fetched}>
                <Show condition={hasData}>
                    <Mapper items={data}>
                        <EventListItem/>
                    </Mapper>
                </Show>
                <Show condition={!hasData}>
                    <EmptyResult icon={<SearchOutlined style={{fontSize: 48}}/>}
                                 title={`"${search}" içeren etkinlik bulunamadı!`}/>
                </Show>
            </Show>
        </div>
    )
}

export default SearchPage;
