import React, {useCallback, useEffect} from 'react';
import {coalasce, getFirstLetters, getLocale, Locales, useApi, useHistory} from "@reactivers/hooks";
import {Button, Image, ListItem, Show,Loading, PageTitle, Card, appStyles} from '@reactivers/neumorphic-ui'
import {MailOutlined, PhoneOutlined, UserOutlined} from "@ant-design/icons";
import {getImageUrl} from "../../utils/functions";

const Profile = props => {
    const {load, response, fetching} = useApi();
    const history = useHistory();
    const reload = useCallback(() => {
        load({
            endpoint: `/users/getUserInfo`
        })
    }, [load])


    useEffect(() => {
        reload()
    }, [reload])

    const pushToEdit = useCallback(() => {
        history.push(`/profile/edit`)
    }, [history])

    const {data: _data} = response;
    const data = _data || {}
    const {image, fullName, email, phoneNumber} = data;
    const {id: imageId} = image || {}

    return (
        <div style={{margin: '0 16px'}}>
            <PageTitle title={getLocale({name: Locales.Profile})}/>
            <Show condition={fetching}>
                <Loading/>
            </Show>
            <Show condition={!fetching}>
                <div style={{...appStyles.center, marginBottom: 16}}>
                    <Image src={getImageUrl(imageId)}
                           className="neumorphic-outset"
                           size={192}
                           placeholder={getFirstLetters(fullName)}/>
                </div>
                <Card title={getLocale({name: Locales.Profile})}>
                    <ListItem avatar={<UserOutlined/>} title={fullName}/>
                    <ListItem avatar={<MailOutlined/>} title={email}/>
                    <ListItem avatar={<PhoneOutlined/>} title={coalasce(phoneNumber, "-")}/>
                    <div style={{padding: "16px 0"}}>
                        <Button type="primary" onClick={pushToEdit}>
                            {getLocale({name: Locales.Edit})}
                        </Button>
                    </div>
                </Card>
            </Show>
        </div>
    )
}

export default Profile;
