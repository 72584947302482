import React, {useCallback} from "react";
import {
    dateToDescription,
    generatedColorFromString,
    getLocale,
    isArrayEmpty,
    Locales,
    takeIf,
    useApi,
    useAuth
} from "@reactivers/hooks";
import moment from "moment";
import {appStyles, Button, ListItem, Show, Tag} from '@reactivers/neumorphic-ui'
import {CheckCircleOutlined, EditOutlined, TagOutlined, UnorderedListOutlined, UserOutlined} from "@ant-design/icons";
import {UserTag} from "../../layout";

const EventRenderer = props => {
    const {username} = useAuth();
    const {
        id,
        isCompleted,
        reload,
        title,
        descriptions,
        color: _color,
        tags,
        mini,
        collaborators,
        startDateTime,
        onClick,
        completeCollaborator,
        updateCollaborator,
        completeDateTime,
        updatedDateTime,
        canEdit
    } = props;
    const color = _color || generatedColorFromString(title);
    const timeDescription = moment(startDateTime).format("HH:mm")
    const {load} = useApi();

    const {user: completeUser} = completeCollaborator || {};
    const {username: completeUsername, fullName: completeUserFullname} = completeUser || {}
    const {user: updateUser} = updateCollaborator || {};
    const {username: updateUsername, fullName: updateUserFullname} = updateUser || {}

    const onComplete = useCallback((e) => {
        e.stopPropagation();

        load({
            endpoint: `/events/${id}/complete`,
            method: "POST",
            onSuccess: reload
        })
    }, [load, id, reload])

    return (
        <div style={{borderRadius: 10, backgroundColor: color}}
             onClick={onClick}
             className={`${takeIf(onClick, 'clickable')} 
             neumorphic-outset`}>
            <ListItem title={title}
                      titleStyle={{
                          marginLeft: 24,
                          fontSize: 18,
                          fontWeight: 'bold'
                      }}
                      description={<div style={{color: '#aaa'}}>{timeDescription}</div>}>

                <Show condition={!isArrayEmpty(descriptions || [])}>
                    <ListItem avatar={<UnorderedListOutlined style={{color: "white", marginTop: 8}}/>}
                              headerContainerStyle={{
                                  alignItems: undefined
                              }}
                              title={<ul style={{paddingLeft: 16}}>
                                  {(descriptions || []).map((description, dindex) => {
                                      const {content} = description;
                                      return (
                                          <li key={dindex}>{content}</li>
                                      )
                                  })}
                              </ul>}
                    />
                </Show>
                <Show condition={!mini}>
                    <Show condition={!isArrayEmpty(tags || [])}>
                        <ListItem avatar={<TagOutlined style={{color: "white", marginTop: 8}}/>}
                                  headerContainerStyle={{alignItems: undefined}}
                                  title={<div style={{...appStyles.grid, paddingRight: 4}}>
                                      {
                                          (tags || []).map((_tag, index) => {
                                              const {tag} = _tag
                                              const {name, color} = tag;
                                              return (
                                                  <Tag key={index}
                                                       style={{marginRight: 8, marginBottom: 8}}
                                                       description={tag}
                                                       neumorphic={false}
                                                       color={color}>
                                                      {name}
                                                  </Tag>
                                              )
                                          })
                                      }
                                  </div>}
                        >

                        </ListItem>
                    </Show>
                    <Show condition={!isArrayEmpty(collaborators || [])}>
                        <ListItem avatar={<UserOutlined style={{color: "white", marginTop: 8}}/>}
                                  headerContainerStyle={{alignItems: undefined}}
                                  title={
                                      <div style={{...appStyles.grid, paddingRight: 4}}>
                                          {
                                              (collaborators || []).map((collaborator, index) => {
                                                  const {user} = collaborator;
                                                  return (
                                                      <UserTag key={index}
                                                               style={{marginRight: 8, marginBottom: 8, padding: 8}}
                                                               neumorphic={false}
                                                               user={user}/>
                                                  )
                                              })
                                          }
                                      </div>
                                  }
                        />
                    </Show>

                    <Show condition={updateUserFullname}>
                        <ListItem avatar={<EditOutlined style={{color: "white", marginTop: 8}}/>}
                                  headerContainerStyle={{alignItems: 'flex-start'}}
                                  titleRenderer={
                                      <>
                                          <Show condition={updateUserFullname}>
                                              <div style={{...appStyles.grid}}>
                                                  {getLocale({
                                                      name: Locales.UpdatedBy, params: {
                                                          username: <Tag style={{margin: '0 8px'}}
                                                                         description={updateUserFullname}
                                                                         neumorphic={false}
                                                                         generatedColor>
                                                              {takeIf(updateUsername === username, getLocale({name: Locales.ByYou}), updateUserFullname)}
                                                          </Tag>,
                                                          date: dateToDescription(updatedDateTime)
                                                      }
                                                  })}
                                              </div>
                                          </Show>
                                      </>
                                  }/>
                    </Show>
                </Show>
                <ListItem avatar={<CheckCircleOutlined style={{color: "white", marginTop: 8}}/>}
                          headerContainerStyle={{alignItems: 'flex-start'}}
                          titleRenderer={
                              <>
                                  <Show condition={!isCompleted}>
                                      <Button type='primary' onClick={onComplete}>
                                          {getLocale({name: Locales.Complete})}
                                      </Button>
                                  </Show>
                                  <Show condition={isCompleted && completeUserFullname}>
                                      <div style={{...appStyles.grid}}>
                                          {getLocale({
                                              name: Locales.CompletedBy, params: {
                                                  username: <Tag style={{marginRight: 8, marginLeft: 8}}
                                                                 neumorphic={false}
                                                                 description={completeUserFullname}
                                                                 generatedColor>
                                                      {takeIf(completeUsername === username, getLocale({name: Locales.ByYou}), completeUserFullname)}
                                                  </Tag>,
                                                  date: dateToDescription(completeDateTime)
                                              }
                                          })}
                                      </div>
                                  </Show>
                              </>
                          }/>
            </ListItem>
        </div>
    )
}

export default EventRenderer
