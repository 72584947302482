import React from "react";
import {Button, Field, Image, Show, Upload} from "@reactivers/neumorphic-ui";
import {getLocale, Locales} from "@reactivers/hooks";
import {getImageUrl} from "../../utils/functions";
import {UserOutlined} from "@ant-design/icons";

const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const ImageUploadFormItem = props => {
    const {name, ...rest} = props;
    if (!name)
        return null;
    return (
        <Field name={name} className="center">
            <ImageUpload {...rest}/>
        </Field>
    )
}

const ImageUpload = props => {
    const {value, onChange: _onChange, buttonText: _buttonText, placeholder, size} = props;
    const {base64Data, id} = value || {}
    const buttonText = _buttonText || getLocale({name: Locales.UploadImage})
    const onChange = (image) => {
        getBase64(image).then(base64Data => {
            _onChange(
                {
                    ...(value || {}),
                    fileType: image.type,
                    base64Data,
                    fileName: image.name,
                    fileSize: image.size,
                }
            )
        });
        return false;
    }

    const src = getImageUrl(id);

    return (
        <Upload beforeUpload={onChange}
                fileList={[]}
                className="center">
            <div className="center-column">
                <div className="center neumorphic-outset"
                     style={{
                         backgroundColor: '#eee',
                         flexDirection: 'column',
                         width: size || 250,
                         height: size || 250,
                         borderRadius: '50%',
                         overflow: 'hidden'
                     }}>
                    <Show condition={base64Data}>
                        <img src={base64Data} alt=""
                             style={{width: '100%'}}
                        />
                    </Show>
                    <Show condition={!base64Data && id}>
                        <Image src={src} size={"100%"} placeholder={placeholder}/>
                    </Show>
                    <Show condition={!base64Data && !id}>
                        <UserOutlined style={{fontSize: 64}}/>
                    </Show>
                </div>
                <Button type='link' style={{marginTop: 16}}>{buttonText}</Button>
            </div>
        </Upload>
    )
}

export default ImageUploadFormItem;
