import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import moment from "moment";
import {
    cos,
    getDatesOfYear,
    getLocale,
    getMainColor,
    getMonthDescription,
    getTodayMonth,
    getTodayYear,
    isArrayContains,
    JSONArrayIndexOf,
    JSONToArray,
    Locales,
    monthsNumberArray,
    numberShouldStartWithZero,
    takeIf,
    useApi,
    useHistory
} from "@reactivers/hooks";
import {appStyles, Button, Col, Row, Show} from '@reactivers/neumorphic-ui'
import {ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined, PlusOutlined} from "@ant-design/icons";
import EventListRenderer, {EventFormModal} from "../Event/EventListRenderer";

const UserAgendaView = props => {
    const [dates, setDates] = useState({});
    const year = getTodayYear();
    const [eventFormModal, setEventFormModal] = useState({visible: false})
    const {load, response} = useApi();
    const {data} = useMemo(() => response, [response]);
    const history = useHistory();

    useEffect(() => {
        if (data && data.length)
            setDates(oldDates => {
                data.forEach(event => {
                    const {id} = event;
                    const date = moment(event.startDateTime).format("YYYY-MM-DD");
                    if (!oldDates[date]) {
                        oldDates[date] = []
                    }

                    if (!isArrayContains(oldDates[date], id, "id")) {
                        oldDates[date].push(event);
                    } else {
                        const index = JSONArrayIndexOf(oldDates[date], id, "id");
                        oldDates[date][index] = event;
                    }
                })
                return {...oldDates}
            })
    }, [data])

    const reload = useCallback(() => {
        load({
            endpoint: `/agendas/events`
        })
    }, [load])

    useEffect(() => {
        reload()
    }, [reload])

    const onEventFormModalClose = useCallback(() => {
        setEventFormModal({visible: false})
    }, [])

    const onEventFormModalSuccess = useCallback(() => {
        onEventFormModalClose();
        reload();
    }, [reload, onEventFormModalClose])

    const onEventFormModalDeleteSuccess = useCallback((response) => {
        onEventFormModalClose();
        reload();
    }, [reload, onEventFormModalClose])

    const onNewClick = useCallback((date) => {
        setEventFormModal({visible: true, event: {}, date})
    }, [])

    const eventListRenderer = useCallback((date) => {
        const events = dates[date];
        if (!events) return null;
        return <EventListRenderer events={events}/>
    }, [dates])

    const dateCellRenderer = useCallback((dateNumber, dayDescription, date) => {
        return (
            <DateCell
                dateNumber={dateNumber}
                dayDescription={dayDescription}
                onNewClick={() => onNewClick(date)}
            />
        )
    }, [onNewClick])

    const goBack = useCallback(() => {
        history.goBack();
    }, [history])

    return (
        <div style={{
            width: '100%',
            height: '100vh',
            backgroundColor: 'white',
            overflow: 'hidden',
            position: 'absolute',
            zIndex: 1,
            left: 0,
            top: 0
        }}>
            <EventFormModal {...eventFormModal}
                            onClose={onEventFormModalClose}
                            onSuccess={onEventFormModalSuccess}
                            onDeleteSuccess={onEventFormModalDeleteSuccess}
                            year={year}
            />
            <Agenda
                onNewClick={onNewClick}
                onClose={goBack}
                dateCellRenderer={dateCellRenderer}
                eventListRenderer={eventListRenderer}
            />
        </div>
    )
}

const Agenda = props => {
    const {eventListRenderer, onClose, dateCellRenderer} = props;
    const calendarContainer = useRef(null);
    const [year, setYear] = useState(getTodayYear());
    const [month, setMonth] = useState(getTodayMonth());

    const changeMonth = useCallback((monthChangeValue) => {
        const dummyDate = moment(`${year}-${numberShouldStartWithZero(month)}-01`).add(monthChangeValue, "month")
        const newMonth = dummyDate.get("month") + 1;
        const newYear = dummyDate.get("year");

        setMonth(newMonth)
        setYear(newYear);

    }, [month, year])

    const generateDates = useCallback(() => {
        const yearOfDates = [...getDatesOfYear(year)];
        const dates = {};
        yearOfDates.forEach(date => {
            dates[date] = []
        })
        return {...dates};
    }, [year])

    const animatedScroll = useCallback((element, to) => {
        let degree = 90
        const distance = to - element.scrollLeft;
        const duration = Math.abs(distance) / 100;
        const direction = Math.sign(distance);
        let incrementer = -1

        const interval = setInterval(() => {
            if (!element) {
                clearInterval(interval);
                return;
            }
            const scrollAmount = Math.abs(cos(degree)) * duration * direction
            let currentPosition = element.scrollLeft
            element.scroll(currentPosition + scrollAmount, 0);
            if (direction > 0) {
                if (currentPosition >= to) {
                    clearInterval(interval)
                    element.scroll(to, 0);
                }
            } else {
                if (to >= currentPosition) {
                    clearInterval(interval)
                    element.scroll(to, 0);
                }
            }
            degree += incrementer;
            if (degree >= 90 || degree <= 0)
                incrementer *= -1;
        }, 1)

    }, [])

    const scrollToCurrentMonth = useCallback(() => {
        const windowWidth = window.innerWidth;
        const scrollX = (month - 1) * windowWidth;
        animatedScroll(calendarContainer.current, scrollX)
    }, [animatedScroll, month])

    useEffect(() => {
        if (calendarContainer.current) {
            scrollToCurrentMonth()
        }
    }, [scrollToCurrentMonth])

    const filterBySelectedMonth = useCallback(({date}, monthNumber) => {
        const itemMonth = moment(date).get("month") + 1;
        return itemMonth === monthNumber;
    }, [])

    const dates = useMemo(() => generateDates(), [generateDates])
    const datesArray = JSONToArray(dates, "date", "events");

    return (
        <div style={{backgroundColor: '#eee'}}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                zIndex: 2,
                height: 64,
                color: 'white',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                backgroundColor: getMainColor()
            }} className='neumorphic-outset'>
                <div style={{...appStyles.spreadHorizontally, width: '100%'}}>
                    <div>
                        <div style={{paddingLeft: 24}}>
                            <Show condition={onClose}>
                                <Button icon={<CloseOutlined/>} onClick={onClose}/>
                            </Show>
                        </div>
                    </div>
                    <div style={{...appStyles.center, fontSize: 24, fontWeight: 'bold'}}>
                        <Button type="ghost"
                                icon={<ArrowLeftOutlined/>}
                                onClick={() => changeMonth(-1)}/>
                        <div style={{margin: "0 16px"}}>
                            {getMonthDescription(month)} {year}
                        </div>
                        <Button type="ghost"
                                style={{border: 'none'}}
                                icon={<ArrowRightOutlined/>}
                                onClick={() => changeMonth(+1)}/>
                    </div>
                    <div/>
                </div>
            </div>
            <div style={{overflow: 'hidden', width: '100vw', display: 'flex'}} ref={calendarContainer}>
                {
                    monthsNumberArray.map((monthNumber, mindex) => {
                        return (
                            <div style={{
                                minWidth: '100vw',
                                overflowY: 'auto',
                                overflowX: 'hidden',
                                maxHeight: '100vh',
                                paddingBottom: 64
                            }} key={mindex}>
                                {
                                    datesArray.filter(item => filterBySelectedMonth(item, monthNumber)).map((item, index) => {
                                        const {date} = item;
                                        const dateNumber = moment(date).format("DD")
                                        const dayDescription = moment(date).format("dddd")
                                        const isToday = moment(date).isSame(moment(), 'date');
                                        return (
                                            <div style={{width: '100%'}}
                                                 key={index}>
                                                <Row style={{
                                                    minHeight: 100,
                                                    margin: "16px 8px",
                                                    borderRadius: 10
                                                }}>
                                                    <Col xs={3} sm={3} md={2} lg={2} xl={2}>
                                                        <div style={{height: '100%'}}
                                                             className={takeIf(isToday, "neumorphic-outset")}>
                                                            <Show condition={!!dateCellRenderer}>
                                                                {dateCellRenderer(dateNumber, dayDescription, date)}
                                                            </Show>
                                                        </div>
                                                    </Col>
                                                    <Col xs={9} sm={9} md={10} lg={10} xl={10}>
                                                        <div className='neumorphic-outset'
                                                             style={{padding: 8, height: '100%'}}>
                                                            <Show condition={!!eventListRenderer}>
                                                                {eventListRenderer(date)}
                                                            </Show>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

const DateCell = props => {
    const {dateNumber, dayDescription, onNewClick} = props;
    return (
        <div style={{padding: 8}}>
            <div style={{
                fontSize: 24,
                fontWeight: 'bold',
                marginLeft: 4,
                marginTop: 4
            }}>
                <div style={{...appStyles.spreadHorizontally}}>
                    <div>
                        {dateNumber}
                    </div>

                </div>
            </div>
            <div style={{marginLeft: 4, fontSize: 18}}>
                {dayDescription}
            </div>
            <Button icon={<PlusOutlined/>}
                    onClick={onNewClick}
                    type="primary">
                {getLocale({name: Locales.New})}
            </Button>
        </div>
    )
}

export default UserAgendaView
