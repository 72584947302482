import React, {useCallback, useEffect, useState} from 'react';
import {getLocale, Locales, useApi} from "@reactivers/hooks";
import EventRenderer from "../Event/Renderer";
import {
    Button,
    Col,
    EmptyResult,
    ListItem,
    Loading,
    Mapper,
    Modal,
    Neumorphic,
    PageTitle,
    Show
} from '@reactivers/neumorphic-ui'
import {InboxOutlined} from "@ant-design/icons";

const InviteList = props => {

    const {load, fetching, fetched, response} = useApi();


    const reload = useCallback(() => {
        load({
            endpoint: '/invites'
        })
    }, [load])

    useEffect(() => {
        reload()
    }, [reload])


    const {data} = response
    const hasData = !!(data || []).length

    return (
        <div style={{margin: '0 16px'}}>
            <PageTitle title={getLocale({name: Locales.Invitations})}/>
            <Show condition={fetching}>
                <Loading/>
            </Show>
            <Show condition={fetched}>
                <Show condition={hasData}>
                    <Mapper items={data}>
                        <InvitationRenderer reload={reload}/>
                    </Mapper>
                </Show>
                <Show condition={!hasData}>
                    <EmptyResult
                        icon={<InboxOutlined style={{fontSize: 48}}/>}
                        title={getLocale({name: Locales.NoInvitations})}/>
                </Show>
            </Show>
        </div>
    )
}

const InvitationRenderer = props => {
    const {id, sender, event, reload} = props;
    const {fullName: senderFullName} = sender;
    const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
    const {load: loadDecide, fetching: decideFetching} = useApi();

    const onDecideInvite = useCallback((id, decide) => {
        loadDecide({
            endpoint: `/invites/${id}/decide`,
            method: "POST",
            params: {
                decide
            },
            onSuccess: reload
        })
    }, [loadDecide, reload])

    const showCancelModal = useCallback((id) => {
        setVisibleDeleteModal(true)
    }, [])

    return (
        <Col xs={12} sm={12} md={6} lg={4} xl={3} style={{padding: 8}}>
            <Modal title={getLocale({name: Locales.CancelInviteConfirm})}
                   visible={visibleDeleteModal}
                   onClose={() => setVisibleDeleteModal(false)}
                   footer={
                       <>
                           <Button
                               onClick={() => setVisibleDeleteModal(false)}>{getLocale({name: Locales.Decline})}</Button>
                           <Button type='danger'
                                   onClick={() => onDecideInvite(id, -1)}>{getLocale({name: Locales.Delete})}</Button>
                       </>
                   }
            />
            <Neumorphic style={{padding: 16}}>
                <ListItem title={senderFullName}
                          titleStyle={{fontSize: 18, fontWeight: 'bold'}}
                          description={
                              <>
                                  <Button type="danger"
                                          style={{marginRight: 8}}
                                          disabled={decideFetching}
                                          onClick={() => showCancelModal(id)}>
                                      {getLocale({name: Locales.Delete})}
                                  </Button>
                                  <Button type="primary"
                                          loading={decideFetching}
                                          onClick={() => onDecideInvite(id, 1)}>
                                      {getLocale({name: Locales.Accept})}
                                  </Button>
                              </>
                          }
                />
                <EventRenderer {...event} />
            </Neumorphic>
        </Col>
    )
}

export default InviteList
