import React, {useCallback, useEffect, useState} from 'react';
import {getLocale, Locales, useApi} from "@reactivers/hooks";
import {Button, EmptyResult, ListItem, Loading, Mapper, PageTitle, Show} from '@reactivers/neumorphic-ui'
import EventListRenderer, {EventFormModal} from "./EventListRenderer";
import moment from "moment";
import 'moment/min/locales.min'
moment.locale(navigator.language)

const EventList = props => {
    const {load, fetched, fetching, response} = useApi();

    const reload = useCallback(() => {
        load({
            endpoint: `/events/group/day`
        })
    }, [load])

    useEffect(() => {
        reload()
    }, [reload])

    const data = response.data || [];
    const hasData = !!(data || []).length;

    return (
        <div style={{margin: "0 16px"}}>
            <PageTitle title={getLocale({name: Locales.Events})}/>
            <Show condition={fetched}>
                <Show condition={hasData}>
                    <Mapper items={data}>
                        <EventListItem reload={reload}/>
                    </Mapper>
                </Show>
                <Show condition={!hasData}>
                    <EmptyResult title={getLocale({name: Locales.EmptyEvents})}/>
                </Show>
            </Show>
            <Show condition={fetching}>
                <Loading/>
            </Show>
        </div>
    )
}

export const EventListItem = props => {
    const {events, date, reload} = props;
    const [eventFormModal, setEventFormModal] = useState({visible: false})

    const onNewEvent = useCallback((date) => {
        setEventFormModal({visible: true, date})
    }, [])

    const onEventFormModalClose = useCallback(() => {
        setEventFormModal({visible: false})
    }, [])

    const onEventFormModalSuccess = useCallback(() => {
        onEventFormModalClose();
        reload();
    }, [reload, onEventFormModalClose])

    const onEventFormModalDeleteSuccess = useCallback((response) => {
        onEventFormModalClose();
        reload();
    }, [reload, onEventFormModalClose])

    return (
        <>
            <EventFormModal {...eventFormModal}
                            onClose={onEventFormModalClose}
                            onSuccess={onEventFormModalSuccess}
                            onDeleteSuccess={onEventFormModalDeleteSuccess}
            />
            <ListItem title={moment(date).format("DD MMMM YYYY")}
                      titleStyle={{fontSize: 24}}
                      description={
                          <Button type='link' onClick={() => onNewEvent(date)}>{getLocale({name: Locales.New})}</Button>
                      }
            />
            <EventListRenderer events={events}/>
        </>
    )
}

export default EventList;
