import React, {useCallback} from 'react';
import {getLocale, Locales, useApi, useAuth, useHistory} from "@reactivers/hooks";
import {appStyles, Button, Card, Field, Form, Textfield} from '@reactivers/neumorphic-ui'

const SignIn = props => {
    const {login} = useAuth();
    const {load} = useApi();
    const history = useHistory();

    const onSuccess = useCallback((response) => {
        const {data: _data} = response;
        const data = {..._data};
        login(data);
    }, [login])

    const onSignIn = useCallback((values) => {
        load({
            endpoint: '/users/signin',
            method: "POST",
            params: {
                ...values
            },
            onSuccess
        })
    }, [load, onSuccess])

    const pushToSignUp = useCallback(() => {
        history.push('/sign/up')
    }, [history])

    return (
        <div style={{height: '100vh', ...appStyles.center, backgroundColor: '#eee'}}>
            <div style={{minWidth: 300}}>
                <Card title={getLocale({name: Locales.SignIn})}
                      neumorphic
                      childrenContainerStyle={{margin: 0}}
                      style={{backgroundColor: 'white', width: '100%', padding: 16}}>
                    <Form onFinish={onSignIn}>
                        <Field name="username">
                            <Textfield label={getLocale({name: Locales.Username})} autoComplete="username"/>
                        </Field>
                        <Field name="password">
                            <Textfield type="password" autoComplete="current-password"
                                       label={getLocale({name: Locales.Password})}/>
                        </Field>
                        <div style={{margin: '16px 0'}}>
                            <div style={{...appStyles.spreadHorizontally}}>
                                <Button type="secondary"
                                        onClick={pushToSignUp}
                                        style={{width: '100%', marginRight: 4}}>
                                    {getLocale({name: Locales.SignUp})}
                                </Button>
                                <Button type="primary"
                                        style={{width: '100%', marginLeft: 4}}
                                        htmlType="submit">
                                    {getLocale({name: Locales.SignIn})}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </Card>
            </div>
        </div>
    )
}

export default SignIn;
