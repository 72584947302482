import React, {useCallback, useEffect, useState} from 'react';
import {getLocale, Locales, useApi} from "@reactivers/hooks";
import {Button, ColorPicker, Field, Form, Modal, Show, Textfield, useForm} from '@reactivers/neumorphic-ui'

const TagEdit = props => {
    const {id, onCancel, onSaveSuccess, onDeleteSuccess} = props;
    const [form] = useForm();
    const {load, fetching, response} = useApi();
    const {load: loadSave, fetching: fetchingSave} = useApi();
    const {load: loadDelete, fetching: fetchingDelete} = useApi();
    const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
    const data = response.data

    const reload = useCallback(() => {
        if (!id) return;
        load({
            endpoint: `/usertags/${id}`,
            onSuccess: (response) => {
                const {data} = response;
                form.setFieldsValue({
                    ...data
                })
            }
        })
    }, [id, load, form])

    useEffect(() => {
        reload()
    }, [reload])

    const onDelete = useCallback(() => {
        loadDelete({
            endpoint: `/usertags/${id}`,
            method: "DELETE",
            onSuccess: onDeleteSuccess
        })
    }, [id, loadDelete, onDeleteSuccess])

    const showConfirm = useCallback(() => {
        setVisibleDeleteModal(true)
    }, [])

    const onFinish = useCallback((value) => {
        const options = {
            endpoint: `/usertags`,
            method: "POST"
        }
        if (id) {
            options.endpoint += `/${id}`
        }

        loadSave({
            ...options,
            params: {
                ...(data || {}),
                ...value
            },
            onSuccess: onSaveSuccess
        })
    }, [id, data, loadSave, onSaveSuccess])

    return (
        <div>
            <Modal title={getLocale({name: Locales.DeleteConfirm})}
                   visible={visibleDeleteModal}
                   footer={
                       <>
                           <Button
                               onClick={() => setVisibleDeleteModal(false)}>{getLocale({name: Locales.Decline})}</Button>
                           <Button type='danger' onClick={onDelete}>{getLocale({name: Locales.Delete})}</Button>
                       </>
                   }
            />
            <Form form={form}
                  onFinish={onFinish}>
                <Field name="name">
                    <Textfield label={getLocale({name: Locales.Title})}/>
                </Field>
                <Field name="color" initialValue="black">
                    <ColorPicker label={getLocale({name: Locales.Color})}/>
                </Field>
                <div style={{marginTop: '16px 0'}}>
                    <Button onClick={onCancel} style={{marginRight: 8}} disabled={fetchingDelete || fetchingSave}>
                        {getLocale({name: Locales.Decline})}
                    </Button>
                    <Show condition={id}>
                        <Button onClick={showConfirm}
                                type="danger"
                                style={{marginRight: 8}}
                                disabled={fetchingSave}
                                loading={fetching || fetchingDelete}>
                            {getLocale({name: Locales.Delete})}
                        </Button>
                    </Show>
                    <Button type="primary" htmlType="submit"
                            loading={fetching || fetchingSave}
                            disabled={fetchingDelete}>
                        {getLocale({name: Locales.Save})}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default TagEdit;
