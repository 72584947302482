import {useApi} from "@reactivers/hooks";
import React, {useCallback} from "react";
import {Button, Field, Form, Modal, Textfield} from '@reactivers/neumorphic-ui'

const InviteModal = props => {
    const {visible, onClose, eventId} = props;
    const {load: loadInvite, fetching: fetchingInvite} = useApi();

    const onInvite = useCallback((value) => {
        const {email: receiverId} = value;

        loadInvite({
            endpoint: `/events/${eventId}/invites`,
            method: "POST",
            params: {
                receiverId
            },
            onSuccess: () => {
                onClose()
            }
        })
    }, [loadInvite, eventId, onClose])

    return (
        <Modal title="Paylaş"
               visible={visible}
               onClose={onClose}
               footer={null}
        >
            <Form onFinish={onInvite}>
                <Field name="email"
                       rules={[{type: 'email', required: true, message: "E-mail zorunlu alan!"}]}>
                    <Textfield label="E-mail"/>
                </Field>
                <div style={{marginTop: 8}}>
                    <Button type="secondary" disabled={fetchingInvite} onClick={onClose}>
                        Vazgeç
                    </Button>
                    <Button type="primary" loading={fetchingInvite} style={{marginLeft: 8}}
                            htmlType="submit">
                        Kaydet
                    </Button>
                </div>
            </Form>
        </Modal>
    )
}

export default InviteModal
